import { FC } from 'react';
import { ContentLoader } from '@weave/design-system';

interface RedirectToNWXProps {
  isRedirecting: boolean;
  isCheckingOldPortalAccessRestriction: boolean;
}

const RedirectToNWX: FC<RedirectToNWXProps> = ({
  isRedirecting,
  isCheckingOldPortalAccessRestriction,
}) => {
  if (isCheckingOldPortalAccessRestriction) {
    return <ContentLoader show={true} />;
  }

  return (
    <ContentLoader
      show={isRedirecting}
      message="Hang tight! We're taking you to the all-new Weave app..."
    />
  );
};

export default RedirectToNWX;
