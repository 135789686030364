import { useEffect } from 'react';
import { APP_ENVIRONMENT } from 'shared/constants';
import { isWeaveUser } from 'shared/helpers/utils';
import { useSunsetOldFormsPortalFeatureFlag } from '../feature-flags';
import RedirectToNWX from './redirect-to-nwx.component';

const REDIRECTION_DELAY = 2000; // 2 seconds
const BASE_URL =
  APP_ENVIRONMENT === 'production'
    ? 'https://app.getweave.com'
    : 'https://app.weavedev.net';

interface UseRedirectionToNWXResults {
  isRedirecting: boolean;
  RedirectToNWX: typeof RedirectToNWX;
  isCheckingOldPortalAccessRestriction: boolean;
}

export const useRedirectionToNWX = (): UseRedirectionToNWXResults => {
  const { isCheckingOldPortalAccessRestriction, isOldPortalAccessRestricted } =
    useSunsetOldFormsPortalFeatureFlag();

  /**
   * 1. Weave users should not be redirected to the new Weave app.
   * 2. If the feature flag is still being checked, we should not redirect.
   * 3. If the feature flag is set to restrict access to the old portal, we should redirect.
   */
  const shouldRedirectToNWX =
    !isWeaveUser() &&
    !isCheckingOldPortalAccessRestriction &&
    isOldPortalAccessRestricted;

  useEffect(() => {
    if (!shouldRedirectToNWX) {
      return;
    }

    // Redirect to the new Weave app after 2 seconds.
    setTimeout(() => {
      window.location.href = `${BASE_URL}/forms`;
    }, REDIRECTION_DELAY);
  }, [shouldRedirectToNWX]);

  return {
    isRedirecting: shouldRedirectToNWX,
    RedirectToNWX,
    isCheckingOldPortalAccessRestriction,
  };
};
